



























import { mapGetters } from 'vuex';
import formatDate from '@/helpers/formatDate';
import capitalize from 'lodash/capitalize';
import { getUserTransferReasons } from '@/api/ClientInfo';
import Tooltip from '@/components/tooltip/Tooltip.vue';
import type { UserTransferReason } from '@/api/schema';
import { errorToastMessage } from '@/helpers/errorToastMessage';

export default {
  name: 'UserManagementManualTransfersTab',
  components: {
    Tooltip
  },
  data(): any {
    return {
      loading: false,
      transferReasons: [],
      headers: [
        {
          text: 'Date (UTC)',
          value: 'createdAt'
        },
        {
          text: 'Amount',
          value: 'amount'
        },
        {
          text: 'Type',
          value: 'type',
          sort: false
        },
        {
          text: 'Reason',
          value: 'reason',
          sort: false
        },
        {
          text: 'Message',
          value: 'message',
          sort: false
        },
        {
          text: 'Email',
          value: 'adminEmail',
          sort: false
        }
      ]
    };
  },
  props: {
    userId: {
      type: Number,
      required: true
    }
  },
  watch: {
    operatorId: {
      handler(newId: number | null): void {
        if (newId) {
          this.getTransactionsReasons();
        }
      },
      immediate: true
    }
  },
  computed: {
    ...mapGetters('Onboarding', ['operatorId'])
  },
  methods: {
    formatDate,
    getTransactionsReasons(): Promise<unknown> {
      if (!this.userId) return;

      this.loading = true;

      return getUserTransferReasons(this.userId)
        .then((data: UserTransferReason[]): void => {
          this.transferReasons = data;
        })
        .catch(errorToastMessage)
        .finally((): void => {
          this.loading = false;
        });
    },
    getTransactionType(type: string): string {
      switch (type) {
        case 'WITHDRAW':
          return 'Transfer Out';
        case '"DEPOSIT"':
          return 'Transfer In';
        default:
          return capitalize(type.replace(/_/g, ' ') || '-');
      }
    }
  }
};
