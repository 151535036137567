var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',[_c('v-card',{attrs:{"disabled":_vm.loading}},[_c('v-card-title',[_c('div',{staticClass:"text-h6 font-weight-medium"},[_vm._v("Manual Transfers")])]),_c('v-data-table-with-pagination',{attrs:{"loading":_vm.loading,"items":_vm.transferReasons,"headers":_vm.headers,"must-sort":""},scopedSlots:_vm._u([{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("numeralSpaces")(item.amount,'0,0[00]')))]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.formatDate(item.createdAt, 'yyyy-MM-dd HH:mm', false, false)))]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.getTransactionType(item.type)))]}},{key:"item.message",fn:function(ref){
var item = ref.item;
return [(!!item.message)?_c('Tooltip',{attrs:{"text":item.message,"icon":"mdi-message-processing-outline"}}):_vm._e()]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }